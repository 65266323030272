<template>
  <div class="h-screen flow-root">
    <img src="/images/waitlist.png" class="absolute w-max md:w-screen h-screen fixed top-0" style="z-index: -1;"/>

    <h1 class="text-xl md:text-4xl font-bold text-white bg-blue-700 pt-20 font-sans drop-shadow" style="background-color: #1052b7">Assetlog</h1>

    <div class="p-6 w-full md:w-full mt-2 md:mt-10 md:mx-auto text-center">
      <h1 class="m-auto md:w-1/2 md:text-5xl text-white font-bold">About</h1>


      <div class="overflow-scroll h-96 md:h-full rounded-2xl bg-white md:w-3/4 m-auto mt-4 shadow text-md md:text-lg flow-root font-extralight font-sans antialiased">
        <p class="md:my-10 md:mx-32 mx-4 mt-8 md:my-1">
          <span class="text-xl md:text-4xl font-bold">A</span> lot of people are scared to invest in cryptocurrencies,
          real estate, stocks, etc because they don't understand it OR don't want to lose their money.
        </p>

        <p class="md:my-10 md:mx-32 mx-4 my-1">
          <span class="text-xl md:text-4xl font-bold">S</span>ome who do, don't know if they're making profits or not.
          This is because they don't analyse their assets, know how to analyse their
          assets or have time to analyse their assets.
        </p>

        <p class="md:my-10 md:mx-32 mx-4 my-1">
          <span class="text-xl md:text-4xl font-bold">W</span>hile you take care of other life's businesses, Assetlog takes care of these for you.
          All you need to do is connect your existing assets and Assetlog does the rest; logs and tracks
          them, all in one place.
        </p>

        <p class="md:my-10 md:mx-32 mx-4">
          <span class="text-xl md:text-4xl font-bold">W</span>hat's better? With Assetlog, even though you have no knowledge about asset management,
          our analytical tools <span class="font-bold">manages them on your behalf and tells you when you're in profit!</span>
        </p>
        <div class="h-24 md:hidden"></div>
      </div>
    </div>

    <!--    &lt;!&ndash;      <p>&ndash;&gt;-->
    <!--&lt;!&ndash;        Asset log helps you log/keep track of all your financial/xxx assets e.g Digital Currency, real estate, foreign exchange, stocks, etc.&ndash;&gt;-->
    <!--        Out robust analytical AI tool stays ahead of time to assists you to plan and prepare your future investments and avoidance of potential risks.-->
    <!--&lt;!&ndash;      </p>&ndash;&gt;-->
  </div>
</template>

<script>
export default {
  name: "About",
}
</script>
