<template>
    <router-view/>
</template>

<style>
body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
    @apply bg-gray-100
}

.multiselect-caret {
  z-index: unset;
}
</style>
