export const DEV_WHITELIST = [
    "william.odiomonafe@gmail.com",
    "kamal.misc@gmail.com",
    "temmyjay001@gmail.com",
    "morenikeade@gmail.com",
    "hello@assetlog.co",

    "jaketuriacada@gmail.com",
    "georgemerit38@gmail.com",
    "adamrick1992@gmail.com",
    "frankarab01@gmail.com",
    "zammat4ever@gmail.com",
    "oshovic2@gmail.com",
    "perfectdamsel007@gmail.com",
    "majorfeez@gmail.com",
];